


























// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

#nprogress .bar {
  background: #B71C1C;
}
.error--text {
  color:#B71C1C!important
}
.checkbox-margin {
  margin-top: -20px !important
}
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
