























.title {
  text-align: center;
}
