@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
#nprogress .bar {
  background: #B71C1C;
}
.error--text {
  color: #B71C1C !important;
}
.checkbox-margin {
  margin-top: -20px !important;
}
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.home-background {
  background: url(../img/home_bg_2560.920c9083.jpg) no-repeat;
  background-size: cover;
}
.overlay {
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 53%,
    rgba(255, 255, 255, 0) 100%
  );
}
.intro {
  width: 50%;
}
@media screen and (max-width: 690px) {
.intro {
    width: 90%;
    margin: 25px auto;
}
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.small {
  font-size: 0.675rem;
}
._loading_loadingIcon_2KEMq {
  font-family: -apple-system, BlinkMacSystemFont, "Lato";
  font-weight: 600;
  color: #35495e;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
._loading_loadingIcon_2KEMq {
  font-size: 1.424rem;
  line-height: 1.875rem;
}
@media screen and (min-width: 25rem) {
._loading_loadingIcon_2KEMq {
    font-size: calc(6.932vw - 0.309rem);
}
}
@media screen and (min-width: 50rem) {
._loading_loadingIcon_2KEMq {
    font-size: 3.157rem;
}
}
@media screen and (min-width: 25rem) {
._loading_loadingIcon_2KEMq {
    line-height: calc(7.5vw + 0rem);
}
}
@media screen and (min-width: 50rem) {
._loading_loadingIcon_2KEMq {
    line-height: 3.75rem;
}
}
._loading_loadingIcon_2KEMq {
  display: block;
  margin: 0 auto;
}
._loading_loadingIcon_2KEMq.v-enter-active {
  transition: opacity 1s;
}
._loading_loadingIcon_2KEMq.v-enter {
  opacity: 0;
}
._timeout_title_QmghM {
  text-align: center;
}

/*# sourceMappingURL=chunk-common.2ea2fcdf.css.map*/